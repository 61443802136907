import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router, ResolveEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import {BehaviorSubject, Subscription} from 'rxjs';
import {
	removeContextualHelp,
	getDispatchersList,
	setSelectedTimeOption,
	setTimeOptions,
	incrementRefreshMs,
	setMinutesSinceRefresh,
	refreshIntervalSet,
	getLocationsList,
	getAssetOptions,
	getDriverOptions,
	getLocationOptions,
} from './state/app/app.actions';
import { AppService } from './state/app/app.service';

import { UserService } from './components/user/user.service';
import { RootState } from './app.module';
import { appFeature, selectedEntitySelector } from './state/app/app.selectors';
import { OneMinute, TwoMinutes } from './utils/time-utils';
import { distinctUntilChanged } from 'rxjs/operators';
import { ACTIVE_ACCOUNT_PARAM, LocalStorageService, SELECTED_ENTITY } from './local-storage.service';
import { AppRouteData } from './app.resolver';
import { SelectedEntity } from './state/app/app-types';
import * as _ from 'lodash';
import { NgxPendoService } from 'ngx-pendo';
import {PermissionsService} from '@zonar-ui/auth';

import {
	sidenavGlobalConfig,
} from '../app/reports/shared-report-components/sidenav.config';
import {appName} from "../environments/shared";

const PERSISTENT_PARAMETERS = ['beginDate', 'endDate', 'sdsType', 'tableMalfunctionType', 'tableStartDate', 'tableEndDate'];

@Component({
	selector: 'app-component',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
	sidenavConfig = sidenavGlobalConfig;
	title = appName;
	account;

	constructor(
		private store: Store<RootState>,
		private route: ActivatedRoute,
		private router: Router,
		public appService: AppService,
		public users: UserService,
		private localStorageService: LocalStorageService,
		private ngxPendoService: NgxPendoService,
		private permissionsService: PermissionsService,
		private cd: ChangeDetectorRef,
	) {}

	public activeAcctSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public contextualHelpLoading: boolean;

	public contextualHelpOpen: boolean;

	public contextualHelpHTML: Object;

	public isProd: boolean;

	public minutesSinceRefresh = 0;

	private isFirstLoad = true;

	public hideRefreshBar = false;
	
	appRouteData: AppRouteData;

	ngOnInit() {
		this.appRouteData = this.route.snapshot.data.data as AppRouteData;
		// If active account not selected just return it don't process anything until the active account is selected
		if (!this.appRouteData.activeAccountSelected) {
			return;
		}
		try {
			const user = this.appRouteData.user;
			const activeAccount = this.localStorageService.get(ACTIVE_ACCOUNT_PARAM);
			this.account = activeAccount;
			const isAdmin = (this.appService.isZonarAdmin(user) || this.appService.isCustomerAdmin(activeAccount));
			this.permissionsService.getCurrentCompanyContext().subscribe((company) => {
				company = !!company ? company : {};
				this.pendoInitialize(user, isAdmin, activeAccount, company);
			});
		} catch (err) {
			console.error('Pendo initialization error', err);
		}
		this.activeAcctSelected$.next(true);
		this.subscribeToAppFeature();
		this.route.queryParams.subscribe((params: { days: string }) => {
			if (params.days) { this.store.dispatch(setSelectedTimeOption(params)); }
		});
		this.store.dispatch(setTimeOptions());

		this.store.select(selectedEntitySelector).subscribe((ent: SelectedEntity) => {
			const previousEnt = this.localStorageService.get(SELECTED_ENTITY);
			if (ent.entityType && ent.value) {
				this.localStorageService.set(SELECTED_ENTITY, ent);
				// CAUTION: Do not remove this check, otherwise the page refreshes indefinitely.
				if (!previousEnt || (previousEnt.entityType === ent.entityType && previousEnt.value !== ent.value)
					|| (previousEnt.entityType !== ent.entityType)) {
					window.history.pushState(null, null, this.filterUrlParams(PERSISTENT_PARAMETERS, window));
					window.location.reload();
				}
			}
		});
	}

	ngAfterViewInit(): void {
		this.cd.detectChanges();
	}

	subscribeToAppFeature() {
		this.store.dispatch(getLocationOptions());
		this.store.dispatch(getAssetOptions());
		this.store.dispatch(getDriverOptions());
		this.store.dispatch(getLocationsList());
		let minutesInterval: any;
		this.store.select(appFeature).pipe(distinctUntilChanged())
			.subscribe(app => {
				this.contextualHelpLoading = app.contextualHelpLoading;
				this.contextualHelpOpen = app.contextualHelpOpen;
				this.contextualHelpHTML = app.contextualHelpHTML;
				this.minutesSinceRefresh = app.minutesSinceRefresh;
				//Todo: remove this check and the dispatcher list when odometer jump report no longer requires the toggle
				//this controls the last remaining dispatcher toggle in the ododmeter jumpm reports,
				// and only displays the toggle when the list is loaded for admin roles
				if (app.isAdmin && this.isFirstLoad) {
					this.isFirstLoad = false;
					this.store.dispatch(getDispatchersList({ reload: false }));
				}
				if (app.userId && app.shouldRefresh) {
					if (minutesInterval) {
						clearInterval(minutesInterval);
						minutesInterval = undefined;
					}

					if (!app.refreshInterval) {
						this.appService.refreshData([
							// new GetHosViolationsLiveStatus(this.route.queryParams),
							// new GetLiveStatus(),
							// disabling availability refresh until further notice
							// new RefreshDriverAvailability(this.route.queryParams),
							incrementRefreshMs({ ms: TwoMinutes }),
						]);

						this.store.dispatch(refreshIntervalSet());
					}
				} else if (app.userId && !app.shouldRefresh && !minutesInterval) {
					this.appService.stopRefreshing();

					minutesInterval = setInterval(() => {
						this.store.dispatch(setMinutesSinceRefresh());
					}, OneMinute);
				}
			});

		this.router.events.pipe(distinctUntilChanged()).subscribe(routerEvent => {
			if (routerEvent instanceof ResolveEnd) {
				this.hideRefreshBar = (
					routerEvent.url.indexOf('/availability') > -1
					|| routerEvent.url.indexOf('/sds') > -1
					|| routerEvent.url.indexOf('/hours') > -1
					|| routerEvent.url.indexOf('/odometer') > -1
					|| routerEvent.url.indexOf('/hos-violations') > -1
				);
			}
		})
	}

	public onSideNavMobileMenuButtonToggled(event) {
		this.sidenavConfig.mobileOpened = event;
	}

	private filterUrlParams(keys, windowContext) {
		let newUrlParams = '?';
		const urlParams = new URLSearchParams(windowContext.location.search);
		_.forEach(keys, (key) => {
			if (urlParams.get(key)) {
				newUrlParams += `&${key}=${urlParams.getAll(key)}`;
			}
		});
		return `${windowContext.location.pathname}${newUrlParams}`;
	}

	private pendoInitialize(user, isAdmin, activeAccount, company) {
		this.ngxPendoService.initialize({
			id: user.id,
			name: `${user.firstName} ${user.lastName}`,
			role: isAdmin ? 'admin' : 'user',
		}, {
			id: activeAccount,
			name: company.name,
		});
	}

	turnOffSidebarState() {
		this.store.dispatch(removeContextualHelp());
	}
}
