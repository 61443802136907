import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {RootState} from '../app.module';
import {ApiConfiguration} from '../api/api-configuration';
import {HttpClient} from '@angular/common/http';
import {AppService} from './app/app.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {PermissionsService} from '@zonar-ui/auth';
import {LocalStorageService} from '../local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class FilterService extends AppService {
    malfunctionDrivers;
    malfunctionAssets;
    userProfileId;
    companyId;
    constructor(
        public store: Store<RootState>,
        config: ApiConfiguration,
        http?: HttpClient,
        permissionsService?: PermissionsService,
        private localStorageService?: LocalStorageService,
    ) {
        super(store, permissionsService, config, http);
        permissionsService.getUserProfiles().subscribe((x) => {
            if (x && x.length !== 0) {
                this.userProfileId = x[0].id;
                this.companyId = x[0].companyId;
            }
        });
    }

    query$(type: string, searchTerm: string) {
        switch (type){
            case 'Drivers':
                // return this.getEntityDrivers(searchTerm)
                return this.getSearchViaLegacyEntityApi('drivers', searchTerm);
            case 'Assets':
                // return this.getEntityAssets(searchTerm);
                return this.getSearchViaLegacyEntityApi('assets', searchTerm);
            default:
                return of([]);
        }
    }

    mapper(type: string, data: any[]) {
        switch (type) {
            case 'Drivers':
                // return this.processDrivers(data);
                return this.processDriverHos(data);
            case 'Assets':
                // return this.malfunctionAssets;
                //return this.processAssets(data);
                return this.processAssetHos(data);
            default:
                return [];
        }
    }

    getLocations() {
        // todo solve timing issue with location
        // return this.getEntityLocations().pipe(
        //     map((locations) => locations.map(x => ({title: x.name, value: x.id}))));
        return this.loadLocationOptions().pipe(
            map((locations: any): any => locations.map(x => ({title: x.name, value: x.id})))
        );
    }

    //hos report mapping
    processDriverHos = (drivers) => drivers.map(
        d => {
            let driverName = `${d.fname} ${d.lname}`;
            let exsId = (d.exsid ? `ex: ${d.exsid}` : '');
            let tagId = (d.tagid ? `tag: ${d.tagid}`: '');
            let additionalInfo = ''
            if (exsId !== '' && tagId !== '') {
                additionalInfo = `(${exsId} ${tagId})`
            } else if (exsId !== '') {
                additionalInfo = `(${exsId})`;
            } else if (tagId !== '') {
                additionalInfo = `(${tagId})`;
            }
            return {
                displayValue: `${driverName} ${additionalInfo}`,
                record: d.id
            }
        }
    )
    processAssetHos = (assets) => assets.map(
        d => ({
            displayValue: `${d.fleet}`,
            record: d.id
        })
    )

    // mapping for entity api
    processAssets = (assets) => assets.map(d => ({displayValue: d.name, record: d.id}));
    processDrivers = (drivers) => drivers.map(
        d => (
            {
                displayValue: `${d.legacyFirstName} ${d.legacyLastName}${(d.externalSystemId ? ` (${d.externalSystemId})`
                    : '')}`,
                record: d.id
            })
    );

    getSearchViaLegacyEntityApi(type: string, searchParam?: string) {
        let params = {
            account_code: this.localStorageService.get('activeAccount')
        };
        if(searchParam) params['search'] = searchParam;

        let url = `${environment.urls.legacyEntityApi}/api/v1/${type}`;
        return this.http.get(url, {params});
    }


    getRulesets() {
        let params = {
            account_code: this.localStorageService.get('activeAccount')
        };
        return this.http.get(`${environment.urls.hosBackendApi}/api/v1/rulesets`, {params})
            .pipe(
                map(res => this.retrieveSupportedRulesets(res)),
                catchError((err) => of(err))
            );
    }

    public getEntityAssets(pattern): Observable<any> {
        let request;
        if (pattern) {
            request = `?q=${encodeURIComponent(`name:${pattern}`)}`;
        }
        let url = `${environment.urls.externalApi}/assets`;
        if (request) {
            url = `${url}${request}`;
        }
        const headers = {
            'Zonar-Owner-ID': `User ${this.userProfileId}`
        };
        return this.http.get(url, {headers});

    }
    public getEntityDrivers(pattern): Observable<any> {
        let request;
        if (pattern) {
            request = `?q=${encodeURIComponent(`legacyFirstName:${pattern}+legacyLastName:${pattern}+externalSystemId:${pattern}`)}`;
        }
        let url = `${environment.urls.externalApi}/driverprofiles`;
        if (request) {
            url = `${url}${request}`;
        }
        const headers = {
            'Zonar-Owner-ID': `User ${this.userProfileId}`
        };
        return this.http.get(url, {headers});

    }
    public getEntityLocations(): Observable<any> {
        let url = `${environment.urls.externalApi}/companies/${this.companyId}/locations`;
        const headers = {
            'Zonar-Owner-ID': `User ${this.userProfileId}`
        };
        return this.http.get(url, {headers});
    }

    private retrieveSupportedRulesets(res: any): Array<[{title, value, tooltipContext?}]> {
        const long_name = 21;
        return res.reduce((acc, cur ) => {
            if (cur.supported === true) {
                acc = [...acc, {
                    title: cur.name,
                    value: cur.id,
                    tooltipContext: cur.name.length > long_name ? cur.name : undefined,
                }];
            }
            return acc;
        }, []);
    }
}
