import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';
import {environment} from "../../../environments/environment";
export const sidenavGlobalConfig: SidenavParams = {
    mobileOpened: true,
    footerOffset: true,
    expanded: true,

    /* options:
      childIndentValue?: number;
      displayToAnonymousUser?: boolean;
      expanded?: boolean;
      footerOffset?: boolean;
      hideChangeCompanyButton?: boolean;
      hideCompanyNameInCompactMode?: boolean;
      hideLogoutButton?: boolean;
      lockExpansion?: boolean;
      mobileOpened?: boolean;
      mobileViewWidthBreakpoint?: number;
      useDefaultHelpItem?: boolean;
      disableBackdropClose?: boolean;
      suppressChangeCompanyDialog?: boolean;
    */
};

export const sidenavHeaderConfig = {
    displayName: true, // if we want to display name of the user
    avatarImageUrl: null, // if we want to show a specific avatar picture e.g. user's avatar
    optionalSubtitle: null,
    hideMobileMenuButton: false
};

export const sidenavFooterConfig = {
    items: []
};
