<zui-sidenav
    #sidenav
    [sidenavParams]="sidenavConfig"
    [header]="sidenavHeader"
    [footer]="sidenavFooter"
    (emitSelectedCompany)="selectCompany($event)">
    <zui-progress-spinner
            *ngIf="isLoading$ | async"
            class="progress-spinner"
            color="accent"
            diameter=256
    ></zui-progress-spinner>
    <router-outlet></router-outlet>
</zui-sidenav>
<div class="content-container">
    <zui-footer-component></zui-footer-component>
</div>
