import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { Observable } from "rxjs";
import {delay, filter, take} from "rxjs/operators";
import { AppService } from "./state/app/app.service";
import {SidenavComponent} from "@zonar-ui/sidenav";
import {
    sidenavFooterConfig,
    sidenavGlobalConfig,
    sidenavHeaderConfig
} from "./reports/shared-report-components/sidenav.config";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ACTIVE_ACCOUNT_PARAM, LocalStorageService} from "./local-storage.service";
import {PermissionsService} from "@zonar-ui/auth";
import {DataDogService} from "./datadog.service";

@Component({
	selector: 'app-root',
    templateUrl: 'app-root.component.html'
})
export class AppRootComponent implements OnInit, OnDestroy  {
    @ViewChild('sidenav') zonarSideNav: SidenavComponent;
    sidenavConfig = sidenavGlobalConfig;
    sidenavHeader = sidenavHeaderConfig;
    sidenavFooter= sidenavFooterConfig;
    public isLoading$!: Observable<boolean>;
    public account = undefined;
    public company = undefined;

    constructor(
        private appService: AppService,
        private localStorageService: LocalStorageService,
        private permissionsService: PermissionsService,
        private router: Router,
        private route: ActivatedRoute,
        private datadogService: DataDogService,
    ) {
        // Adding 100 ms delay to avoid flickering effect 
        this.isLoading$= this.appService.isAppDataLoading$.pipe(delay(100));
        this.account = this.localStorageService.get('activeAccount');
    }

    ngOnDestroy() {
        this.datadogService.stopSessionReplayRecording();
    }

    ngOnInit() {
        //Monitoring user in RUM in resolver here right after authentication
        this.datadogService.startSessionReplayRecording();
        this.account = this.localStorageService.get('activeAccount');
        this.permissionsService.getCurrentCompanyContext().pipe(filter(x => !!x), take(1)).subscribe(x => {
            this.company = x;
        });
    }

    selectCompany(selection: { value: string; title: string }): void {

        if (this.account && (this.company?.id !== selection.value)) {
            this.appService.setCompanyInSidenav(selection.value).subscribe(
                ([acc, list]) => {
                    if(acc !== this.account) {
                        this.localStorageService.set(ACTIVE_ACCOUNT_PARAM, acc);
                        // remove the selectedEntity
                        this.localStorageService.remove('selectedEntity');
                        const queryParams: Params = { active: acc };
                        this.router.navigate(
                            [],
                            {
                                relativeTo: this.route,
                                queryParams,
                            }
                        )
                            .then(() => {
                                window.location.reload();
                        });
                    }
                }
            );
        }
    }
}
