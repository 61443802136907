<div class='dispatcher-contain'>
    <div class="dispatcher-list">
    <zui-searchable-dropdown
            label="Divisions"
            [isMultiple]="false"
            [options]="accountList"
            [sortByStringTitle]="true"
            [z_formGroup]="accountForm"
            [selectOptionsControlName]="'account'"
            (selected)="accountSelection($event)"
            [searchableThreshold]="4"
    >
    </zui-searchable-dropdown>
    </div>
</div>
