import { first, map, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
	HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectedEntitySelector } from 'app/state/app/app.selectors';
import { RootState } from '../../app.module';
import { AuthService } from '@auth0/auth0-angular';
import { ACTIVE_ACCOUNT_PARAM, LocalStorageService, SELECTED_DISPATCHER_ID, SELECTED_LOCATION_ID } from 'app/local-storage.service';
import {environment} from '../../../environments/environment';
// import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private entityArg;

	constructor(
		private router: Router,
		private authService: AuthService,
		private activeRoute: ActivatedRoute,
		public store: Store<RootState>,
		private localStorageService: LocalStorageService,
	) {
		this.store.select(selectedEntitySelector).subscribe((ent) => this.entityArg = ent);
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Workaround for the fact that 'type' is now an illegal prop in actions
		// other option is to update all of our APIs
		const selectedEntity = JSON.stringify(this.entityArg).replace(/entityType/gi, 'type');

		if(!request.url.includes('/core/v1/')
			&& !request.url.includes(environment.urls.hosBackendApi)
			&& !request.url.includes(environment.urls.legacyEntityApi)
			&& !request.url.includes('/core/v1beta2/')
			&& !request.url.includes('storage.googleapis.com')
		) {
			request = request.clone({
				setParams: {
					selectedEntity,
					withCredentials: 'true',
				},
				headers: request.headers
					.set(ACTIVE_ACCOUNT_PARAM, this.localStorageService.get(ACTIVE_ACCOUNT_PARAM) || '')
					.set(SELECTED_DISPATCHER_ID, this.localStorageService.getDispatcherId())
					.set(SELECTED_LOCATION_ID, this.localStorageService.getLocationId())
			});
			// console.log(request.url, request.headers);
		}
		return next.handle(request);
		// send cloned request with header to the next handler.
        // return this.authService.user$.pipe(first(),mergeMap(authUser => {
        //     // Clone the request and replace the original headers with
        //     // cloned headers, updated with the user.
        //     const addUserInRequest = request.clone({
        //         headers: request.headers.set('userEmail', authUser?.email)
        //     });
        //     return next.handle(addUserInRequest);
        // }))

		// return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
		// 	if (event instanceof HttpResponse) {
		// 		// do stuff with response if you want
		// 	}
		// }, (error: any) => {
		// 	// if (error instanceof HttpErrorResponse) {
		// 	// 	if (this.router.url !== '/login' && this.router.url !== '/logout' && error.status === 401) {
		// 	// 		this.authService.logout();
		// 	// 	}
		// 	// }
		// }));
	}
}
