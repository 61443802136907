import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup} from '@angular/forms';
import {AppService} from "../../../state/app/app.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ACTIVE_ACCOUNT_PARAM, LocalStorageService} from "../../../local-storage.service";

@Component({
    selector: 'division-selector',
    templateUrl: './division-selector.component.html',
    styleUrls: ['./division-selector.component.scss']
})
export class DivisionSelectorComponent implements OnInit {
    public accountList: {title: string, value: any}[]= [];
    public accountForm = new FormGroup({
        account: new FormControl()
    });

    constructor(
        private appService: AppService,
        private localStorage: LocalStorageService,
        private router: Router,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.appService.getAccountList().subscribe(accList => {
            if (!this.accountForm.get('account')) {
                this.accountForm.addControl('account', new FormControl());
            }
            this.accountForm.get('account').setValue('');
            this.accountList = [...accList];
            const activeAccount = this.localStorage.get('activeAccount');
            if (this.accountList.length > 0) {
                if (activeAccount) {
                    this.accountForm.get('account').setValue(activeAccount, {emitEvent: false});
                } else {
                    this.accountForm.get('account').setValue(this.accountList[0], {emitEvent: false} );
                }
            }
            // Trigger change detection manually
            this.cdRef.detectChanges();
        })
    }

    accountSelection(account) {
        const selectedAccount = account[0].value;
        const currentActiveAccount = this.localStorage.get('activeAccount');
        if(currentActiveAccount !== selectedAccount) {
            // removing stored activeAccount and selectedEntity
            this.localStorage.remove('activeAccount');
            this.localStorage.remove('selectedEntity');
            const queryParams: Params = { active: selectedAccount };
            this.router.navigate(
                [],
                {
                    relativeTo: this.route,
                    queryParams,
                }
            )
            .then(() => {
                window.location.reload();
            });
        }
    }
}
