import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'report-title',
	templateUrl: './report-title.component.html',
	styleUrls: ['./report-title.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTitleComponent {

	@Input()
	public title: string;

	@Input()
	public helpUrl: string = undefined;

	@Input()
	public showToggle: boolean = false;

	@Input()
	public toggleChecked: boolean = false;

	@Input()
	public toggleTitle: string = 'Group issues by asset';

	@Output() toggleChange = new EventEmitter<boolean>();

	constructor() {}

	emitToggleChange() {
		this.toggleChecked = !this.toggleChecked;
		this.toggleChange.emit(this.toggleChecked);
	}
}
